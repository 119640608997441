<template>
    <div>
        <!-- Navbar -->
        <nav class="main-header navbar navbar-expand bg-white navbar-light border-bottom">
        <!-- Left navbar links -->
            <ul class="navbar-nav">
                <li class="nav-item">
                    <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
                </li>
             </ul>
             <ul class="navbar-nav ml-auto">
                 <li class="nav-item">
                    <router-link :to="{name: 'profile'}">
                        <a class="nav-link blue" href="#">{{ $store.getters.getUser.display }}</a>
                    </router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#" v-on:click.stop.prevent="logout">
                        <i class="nav-icon fas fa-power-off blue"></i>
                    </a>
                </li>
             </ul>
            <!-- SEARCH FORM -->
            <!-- <form class="form-inline ml-3">
                <div class="input-group input-group-sm">
                    <input class="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search">
                    <div class="input-group-append">
                        <button class="btn btn-navbar" type="submit">
                            <i class="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form> -->
        </nav>
        <!-- /.navbar -->
    </div>
</template>
<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        },
        methods: {
            logout() {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to Logout",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Logout It!'
                }).then((result) => {
                    if (result.value) {
                        axios.post("/logout").then(()=>{
                            localStorage.clear();
                            location.reload();
                        }).catch(() => {
                            localStorage.clear();
                            location.reload();
                        });
                    }
                })
            }
        }
    }
</script>