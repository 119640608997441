import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);
const Dashboard = () => import('./components/Dashboard');
const NotFound = () => import('./components/NotFound');
const Profile = () => import('./components/Profile');
const Semesters = () => import('./components/Semesters');
const Students = () => import('./components/Students');
const Users = () => import('./components/Users');
const Teachers = () => import('./components/Teachers');
const Products = () => import('./components/products/Products');
const Classes = () => import('./components/classes/Index');
const ClassesAddStudents = () => import('./components/classes/AddStudents');
const ClassesManageGrades = () => import('./components/classes/Grades');
const Subjects = () => import('./components/Subjects');
const Courses = () => import('./components/Courses');
const Sections = () => import('./components/sections/Index');
const SectionsEdit = () => import('./components/sections/Edit');
const SectionsCreate = () => import('./components/sections/Create');
const SectionsView = () => import('./components/sections/View');
const GradeSheets = () => import('./components/sheets/Index');
const GradeSheetsCreate = () => import('./components/sheets/Create');
const GradeSheetsView = () => import('./components/sheets/View');
const GradeSheetsEdit = () => import('./components/sheets/Edit');
const GradesIndex = () => import('./components/grades/Index');
const GradesCreate = () => import('./components/grades/Create');
const GradesEdit = () => import('./components/grades/Edit');
const GradesStudent = () => import('./components/grades/Student');
const Registrations = () => import('./components/registrations/Index');
const RegistrationsCreate = () => import('./components/registrations/Create');
const RegistrationsEdit = () => import('./components/registrations/Edit');
const RegistrationsEnroll = () => import('./components/registrations/Enroll');
const Enrolls = () => import('./components/enrolls/Index');
const EnrollsCreate = () => import('./components/enrolls/Create');
const EnrollsView = () => import('./components/enrolls/View');
const Settings = () => import('./components/settings/Index');
const Registrars = () => import('./components/Registrar');
const Departments = () => import('./components/Departments');
const SummaryReport = () => import('./components/SummaryReport');


const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', name: 'dashboard', component: Dashboard },
        { path: '/profile', name: 'profile', component: Profile },
        { path: '/classes', name: 'classes', component: Classes },
        { path: '/classes/:id/manage-students', name: 'classes.students', component: ClassesAddStudents },
        { path: '/classes/:id/manage-grades', name: 'classes.grades', component: ClassesManageGrades },
        { path: '/students', name: 'students', component: Students },
        { path: '/teachers', name: 'teachers', component: Teachers },
        { path: '/subjects', name: 'subjects', component: Subjects },
        { path: '/sections', name: 'sections', component: Sections },
        { path: '/sections/create', name: 'sections.create', component: SectionsCreate },
        { path: '/sections/:id/edit', name: 'sections.edit', component: SectionsEdit },
        { path: '/sections/:id/view', name: 'sections.view', component: SectionsView },
        { path: '/grade-sheets', name: 'grade-sheets', component: GradeSheets },
        { path: '/grade-sheets/create', name: 'grade-sheets.create', component: GradeSheetsCreate },
        { path: '/grade-sheets/:id/view', name: 'grade-sheets.view', component: GradeSheetsView },
        { path: '/grade-sheets/:id/edit', name: 'grade-sheets.edit', component: GradeSheetsEdit },
        { path: '/grades', name: 'grades', component: GradesIndex },
        { path: '/grades/create', name: 'grades.create', component: GradesCreate },
        { path: '/grades/student', name: 'grades.student', component: GradesStudent },
        { path: '/grades/:id/edit', name: 'grades.edit', component: GradesEdit },
        { path: '/courses', name: 'courses', component: Courses },
        { path: '/administrators', name: 'administrators', component: Users },
        { path: '/settings/admins', name: 'settings.users', component: Users },
        { path: '/settings/semesters', name: 'settings.semesters', component: Semesters },
        { path: '/registrations', name: 'registrations', component: Registrations },
        { path: '/registrations/create', name: 'registrations.create', component: RegistrationsCreate },
        { path: '/registrations/:id/edit', name: 'registrations.edit', component: RegistrationsEdit },
        { path: '/registrations/:id/enroll', name: 'registrations.enroll', component: RegistrationsEnroll },
        { path: '/enrolls', name: 'enrolls', component: Enrolls },
        { path: '/enrolls/create', name: 'enrolls.create', component: EnrollsCreate },
        { path: '/enrolls/:id/view', name: 'enrolls.view', component: EnrollsView },
        { path: '/settings', name: 'settings', component: Settings },
        { path: '/registrars', name: 'registrars', component: Registrars },
        { path: '/departments', name: 'departments', component: Departments },
        { path: '/summary-reports', name: 'summary-reports', component: SummaryReport },
        { path: '*', name: 'not-found', component: NotFound}
    ]
});

// router.beforeEach((to, from, next) => {
//     // if (localStorage.getItem('selectedCorporation') === null) {
//     //     // checking to avoid loop
//     //     if (to.name === 'switch-corporation') {
//     //         return next();   
//     //     } else if (localStorage.getItem('user') === null && localStorage.getItem('selectedCorporation') === null && localStorage.getItem('userPermissions') === null) {
//     //         return next();
//     //     } else {
//     //         next({
//     //             path: '/corporations/switch'
//     //         });
//     //     }
//     // }
//     // else {
//     //    next();
//     // }
// });

export default router;
