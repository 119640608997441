export default class Gate{

    constructor(user){
        this.role = user.roles[0];
    }

    isAdmin(){
        return this.role.name === 'admin';
    }

    isStudent(){
        return this.role.name === 'student';
    }
    
    isTeacher(){
        return this.role.name === 'teacher';
    }

    isRegistrar(){
        return this.role.name === 'registrar';
    }

    isDepartment(){
        return this.role.name === 'department';
    }
}