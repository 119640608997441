<template>
    <div>
        <navbar></navbar>
        <sidebar></sidebar>
        <transition name="fade" mode="out-in">
            <div class="content-wrapper">
                <div class="content">
                    <div class="container-fluid">
                        <router-view></router-view>
                        <vue-progress-bar></vue-progress-bar>
                    </div>
                </div>
            </div>
        </transition>
        <footer class="main-footer">
            <div class="float-right d-none d-sm-inline">
            </div>
            <strong><a></a>.</strong>
        </footer>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                activityLog : {},
                activityLogs : {},
                form: new Form({
                    id:'',
                    name : '',
                    email: '',
                    password: '',
                    type: '',
                    bio: '',
                    photo: ''
                })
            }
        },

        created() {
            axios.get('/api/auth/user').then(res => {
                this.$store.dispatch("setUser", res.data.data);
                localStorage.setItem("role", JSON.stringify(res.data.data.roles[0]));
            });

            axios.get('/api/settings').then(res => {
                this.$store.dispatch("setSetting", res.data.data);
            });
        },

        mounted() { 
            if (this.$gate.isStudent()) {
                this.$router.push({ name: 'profile' });
            }

            console.log('Component mounted.') 
        }
    }
</script>