<template>
    <div>
        <aside class="main-sidebar sidebar-light-info elevation-4">
            <router-link :to="{name: 'dashboard'}" class="brand-link blue nav-item">
                <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp" alt="The Logo" height="60" width="60" class="img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light blue">Enrollment System</span>
            </router-link>
            <div class="sidebar">
                <div class="user-panel mt-3 pb-3 mb-3 d-flex blue">
                    <div class="image">
                        <!-- <i class="nav-icon fas fa-id-card blue fa-2x"></i> -->
                    </div>
                    <div class="info" v-if="$store.getters.getSetting.semester">
                        {{ $store.getters.getSetting.semester.display }}
                    </div>
                </div>
                <nav class="mt-2">
                    <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{ name: 'dashboard'}" class="nav-link"> 
                                <i class="nav-icon fas fa-tachometer-alt blue"></i>
                                <p class="blue">Dashboard</p>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="#" class="nav-link">
                                <i class="nav-icon fas fa-solid fa-school blue"></i>
                                <p class="blue">School Year</p>
                            </a>
                        </li> -->
                        <li class="nav-item" v-if="$gate.isAdmin() || $gate.isDepartment() || $gate.isRegistrar()">
                            <router-link :to="{name: 'registrations'}" class="nav-link">
                                <i class="nav-icon fas fa-file-alt blue"></i>
                                <p class="blue">Registration</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin() || $gate.isDepartment()">
                            <router-link :to="{name: 'enrolls'}" class="nav-link">
                                <i class="nav-icon fas fa-university blue"></i>
                                <p class="blue">Enrolled Student</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'courses'}" class="nav-link">
                                <i class="nav-icon fas fa-user-graduate blue"></i>
                                <p class="blue">Courses</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'departments'}" class="nav-link">
                                <i class="nav-icon fas fa-building blue"></i>
                                <p class="blue">Department Head</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'registrars'}" class="nav-link">
                                <i class="nav-icon fas fa-archive blue"></i>
                                <p class="blue">Registrar</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'administrators'}" class="nav-link">
                                <i class="nav-icon fas fa-user-cog blue"></i>
                                <p class="blue">Administrators</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'summary-reports'}" class="nav-link">
                                <i class="nav-icon fas fa-file-alt blue"></i>
                                <p class="blue">Summary Report</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'settings'}" class="nav-link">
                                <i class="nav-icon fas fa-cog blue"></i>
                                <p class="blue">Settings</p>
                            </router-link>
                        </li>
                        <!-- <li class="nav-item" v-if="$gate.isAdmin() || $gate.isTeacher()">
                            <router-link :to="{name: 'grade-sheets'}" class="nav-link">
                                <i class="nav-icon fas fa-file-alt blue"></i>
                                <p>Grade Sheets</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin() || $gate.isTeacher()">
                            <router-link :to="{name: 'grades'}" class="nav-link">
                                <i class="nav-icon fas fa-clipboard-list blue"></i>
                                <p>Grades</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isStudent()">
                            <router-link :to="{name: 'grades.student'}" class="nav-link">
                                <i class="nav-icon fas fa-clipboard-list blue"></i>
                                <p>Grades</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'subjects'}" class="nav-link">
                                <i class="nav-icon fas fa-book blue"></i>
                                <p>Subjects</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'teachers'}" class="nav-link">
                                <i class="nav-icon fas fa-chalkboard-teacher blue"></i>
                                <p>Teachers</p>
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="$gate.isAdmin()">
                            <router-link :to="{name: 'students'}" class="nav-link">
                                <i class="nav-icon fas fa-book-reader blue"></i>
                                <p>Students</p>
                            </router-link>
                        </li>
                        <li class="nav-item has-treeview" v-if="$gate.isAdmin()">
                            <a href="#" class="nav-link" v-if="$gate.isAdmin()">
                                <i class="nav-icon fas fa-cog blue"></i>
                                <p>
                                    Settings
                                    <i class="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul class="nav nav-treeview" v-if="$gate.isAdmin()">
                                <li class="nav-item">
                                    <router-link :to="{name: 'settings.users'}" class="nav-link">
                                        <i class="nav-icon fas fa-user-cog blue"></i>
                                        <p>Administrators</p>
                                    </router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link :to="{name: 'settings.semesters'}" class="nav-link">
                                        <i class="nav-icon fas fa-calendar-alt blue"></i>
                                        <p>Semesters</p>
                                    </router-link>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#" v-on:click.stop.prevent="logout">
                                <i class="nav-icon fas fa-power-off blue"></i>
                                <p class="blue">Logout</p>
                            </a>
                        </li> -->
                    </ul>
                </nav>
            </div>
        </aside>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                user: {},
                corporation: {},
                user_permissions : [],
                settings : true
            };
        },
        
        methods: {
            logout() {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You want to Logout",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, Logout It!'
                }).then((result) => {
                    if (result.value) {
                        axios.post("/logout").then(()=>{
                            localStorage.clear();
                            location.reload();
                        }).catch(() => {
                            localStorage.clear();
                            location.reload();
                        });
                    }
                })
            }
        },

        mounted() {
            console.log('Component mounted.')
        }
    }
</script>