<template>
    <section class="content" v-if="$gate.isAdmin()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-light">
                        <div class="card-header">
                            <h3 class="card-title">School Year List</h3>
                            <div class="card-tools"> 
                                <!-- <button class="btn btn-light" @click="resetSemesters">
                                    <i class="fas fa-sync-alt"></i>
                                    Reset
                                </button> -->
                                <button class="btn btn-light" @click="showSearchModal">
                                    <i class="fas fa-fw fa-search"></i>
                                    Search
                                </button>                          
                                <button type="button" class="btn btn-light" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>School Year</th>
                                        <th>Created By</th>
                                        <th>Created Date</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="school_year in school_years.data" :key="school_year.id">
                                        <td>{{ school_year.name }}</td>
                                        <td>{{ school_year.year_from }}  - {{ school_year.year_to }}</td>
                                        <td>{{ school_year.user.first_name }} {{ school_year.user.middle }} {{ school_year.user.last_name }}</td>
                                        <td>{{school_year.created_at | myDate }}</td>
                                        <td class="text-center">
                                            <a href="#" @click="editModal(school_year)">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            &nbsp
                                            <a href="#" @click="deleteSchoolYear(school_year.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <pagination :data="school_years" @pagination-change-page="getResults" :limit="1"></pagination>
                <!-- /.card -->
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New School Year</h5>
                            <h5 class="modal-title" v-show="editmode">Update School Year's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="editmode ? updateSchoolYear() : createSchoolYear()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label><a class="red">* </a> Name</label>
                                    <input v-model="form.name" type="text" name="name"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                    <has-error :form="form" field="name"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Description</label>
                                    <input v-model="form.description" type="text" name="description"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                                    <!-- <has-error :form="form" field="description"></has-error> -->
                                </div>
                                <div class="form-group">
                                    <label><a class="red">* </a> Year From</label>
                                    <input v-model="form.year_from" type="number" name="year_from"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('year_from') }">
                                    <has-error :form="form" field="year_from"></has-error>
                                </div>
                                <div class="form-group">
                                    <label><a class="red">* </a> Year To</label>
                                    <input v-model="form.year_to" type="number" name="year_to"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('year_to') }">
                                    <has-error :form="form" field="year_to"></has-error>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="searchSemester" tabindex="-1" role="dialog" aria-labelledby="searchSemester" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Search School Year</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="loadSchoolYears()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>School Year</label>
                                    <input type="text" v-model="search.name" class="form-control" placeholder="School Year">
                                </div>
                                <div class="form-group">
                                    <label><a class="red">* </a> Year From</label>
                                    <input v-model="search.year_from" type="number" name="year_from" class="form-control" placeholder="Year From">
                                </div>
                                <div class="form-group">
                                    <label><a class="red">* </a> Year To</label>
                                    <input v-model="search.year_to" type="number" name="year_to" class="form-control" placeholder="Year From">
                                </div>
                                <div class="form-group">
                                    <label>Order By</label>
                                    <select class="form-control" v-model="search.order_by">
                                        <option value="desc">Newest</option>
                                        <option value="asc">Oldest</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-success">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
        <not-found></not-found>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                school_years: {},
                search: {
                    name: '',
                    year_from: '',
                    year_to: '',
                    order_by: 'desc'
                },
                school_year: {},
                form: new Form({
                    id : '',
                    name: '',
                    description: '',
                    year_from: '',
                    year_to: '',
                    user: {}
                })
            }
        },
        methods: {
            getResults(page = 1) {
                let params = this.search;
                this.$Progress.start();
                axios.get('/api/school-years?page=' + page, { params }).then(({ data }) => (this.school_years = data));
                this.$Progress.finish();
            },
            loadSchoolYears(){
                let params = this.search;
                axios.get("/api/school-years", {params}).then(({ data }) => (this.school_years = data));
                $('#searchSemester').modal('hide');
            },
            createSchoolYear(){
                this.$Progress.start();
                this.form.post('/api/school-years')
                .then((data)=>{
                    $('#addNew').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadSchoolYears();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateSchoolYear(){
                this.$Progress.start();
                this.form.put('/api/school-years/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNew').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    this.form.reset();
                        //  Fire.$emit('AfterCreate');
                    this.loadSchoolYears();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },
            editModal(school_year){
                this.editmode = true;
                this.form.reset();
                $('#addNew').modal('show');
                this.form.fill(school_year);
            },
            showSearchModal(){
                this.form.reset();
                $('#searchSemester').modal('show');
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNew').modal('show');
            },
            deleteSchoolYear(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/school-years/'+id).then(()=>{
                            Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                            );
                            this.loadSchoolYears();
                        }).catch((data)=> {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                    }
                })
            },
            resetSemesters() {
                this.search = {
                    name: '',
                    year_from: '',
                    year_to: '',
                    order_by: 'desc'
                };

                this.loadSchoolYears();
            },
            selectedSchoolYear(value) {
                this.form.school_year_id = value.id
            }
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.loadSchoolYears();
            this.$Progress.finish();
        }
    }
</script>
