<template>
    <section class="content" v-if="$gate.isAdmin()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Settings</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>School Year</label>
                        <v-select label="name" v-model="form.school_year" :options="school_years"  @input="selectedSchoolYear"></v-select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Semester</label>
                        <v-select label="display" v-model="form.semester" :options="selectedSemesters"  @input="selectedSemester"></v-select>
                    </div>
                </div>
                <!-- <div class="col-sm-4">
                    <div class="form-group">
                        <label>Middle Name</label>
                        <input type="text" v-model="form.middle_name" class="form-control" placeholder="Middle Name" :disabled="isDisabled">
                        <has-error :form="form" field="middle_name"></has-error>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Last Name</label>
                        <input type="text" v-model="form.last_name" class="form-control" placeholder="Last Name" :disabled="isDisabled">
                        <has-error :form="form" field="last_name"></has-error>
                    </div>
                </div>  -->   
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <button type="button" @click="createSetting" class="btn btn-primary btn-lg">
                        <i class="nav-icon fas fa-cog"></i>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
        <not-found></not-found>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                selectedSemesters : [],
                semesters : [],
                school_years: [],
                form: new Form({
                    id : '',
                    user_id: '',
                    semester_id: '',
                    school_year_id: '',
                    school_year: {},
                    user: {},
                    semester: {}
                })
            }
        },
        methods: {
            loadSemesters(){
                axios.get("/api/semesters/get-all-semesters").then(({ data }) => (this.semesters = data.data));
            },
            loadSchoolYears(){
                axios.get("/api/school-years/get-all-school-years").then(({ data }) => (this.school_years = data.data));
            },
            loadSetting() {
                axios.get("/api/settings").then(({ data }) => (this.form.fill(data.data)));
            },
            createSetting(){
                this.$Progress.start();
                this.form.post('/api/settings')
                .then((data)=>{
                    $('#addNewSemester').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();

                    this.$store.dispatch("setSetting", data.data.data);
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            selectedSchoolYear(value) {
                this.form.school_year_id = value.id
                this.selectedSemesters = this.semesters.filter(semester => semester.school_year_id == value.id);
            },
            selectedSemester(value) {
                this.form.semester_id = value.id
            }
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.loadSemesters();
            this.loadSchoolYears();
            this.loadSetting();
            this.$Progress.finish();
        }
    }
</script>
