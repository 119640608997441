<template>
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 blue">{{ title }}</h1>
                    <button class="btn btn-light" @click="print" v-if="enablePrint">
                        <i class="fa fa-print"></i>
                        Print
                    </button>
                </div>
                <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                        <li class="breadcrumb-item">
                            <router-link :to="{ name: `${routePrefixName}` }"><span class="text-success">{{ title }}</span></router-link>
                        </li>
                        <li v-if="childTitle" class="breadcrumb-item active">{{ childTitle }}</li>
                    </ol>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        routePrefixName: {
            type: String,
            required: true,
        },
        childTitle: {
            type: String,
            required: false,
        },
        enablePrint: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        print() {
            window.print();
        }
        // getCurrentAction() {
        //     if (this.showOnlyAction) {
        //         return this.action;
        //     }
        //     if (this.useName == "singular") {
        //         return `${this.action} ${this.singularName}`;
        //     }
        //     if (this.useName == "plural") {
        //         return `${this.action} ${this.pluralName}`;
        //     }
        //     if (this.useName == "alternate") {
        //         return `${this.action} ${this.alternateName}`;
        //     }
        // },
    },
};
</script>