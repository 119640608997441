<template>
    <section class="content" v-if="$gate.isAdmin()">
        <breadcrumbs
            :title="title"
            :routePrefixName="routePrefixName"
        ></breadcrumbs>
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-6">
                    <div class="small-box bg-info">
                        <div class="inner">
                            <h3>{{ dashboard.registered_student }}</h3>
                            <p>Registered Student</p>
                        </div>
                        <div class="icon">
                            <i class="fas fa-file-alt"></i>
                        </div>
                        <router-link :to="{name: 'registrations'}" class="small-box-footer">
                            More info <i class="fas fa-arrow-circle-right"></i>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-6">
                    <div class="small-box bg-info">
                        <div class="inner">
                            <h3>{{ dashboard.enrolled_student}}</h3>
                            <p>Enrolled Student</p>
                        </div>
                        <div class="icon">
                            <i class="fas fa-user-graduate"></i>
                        </div>
                        <router-link :to="{name: 'enrolls'}" class="small-box-footer">
                            More info <i class="fas fa-arrow-circle-right"></i>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="card card-light">
                        <div class="card-header">
                            <h3 class="card-title">Enrolled Student by Course</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table m-0">
                                    <thead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Enrolled</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(course) in dashboard.courses">
                                            <td>{{ course.course.name }}</td>
                                            <td>{{ course.enrolled_count }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer">
                            <router-link :to="{name: 'enrolls'}" class="small-box-footer">
                                More info <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card card-light">
                        <div class="card-header">
                            <h3 class="card-title">Enrolled Student by Grade Level</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table m-0">
                                    <thead>
                                        <tr>
                                            <th>Grade Level</th>
                                            <th>Enrolled</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(enrolled, key) in dashboard.year_enrolled">
                                            <td>{{ key }} Year</td>
                                            <td>{{ enrolled }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer">
                            <router-link :to="{name: 'enrolls'}" class="small-box-footer">
                                More info <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="row">
                <div class="col-md-6">
                    <div class="card card-light">
                        <div class="card-header">
                            <h3 class="card-title">Enrolled Student by Grade Level</h3>
                            <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                            </div>
                        </div>
                        <div class="card-body p-0">
                            <div class="table-responsive">
                                <table class="table m-0">
                                    <thead>
                                        <tr>
                                            <th>Grade Level</th>
                                            <th>Enrolled</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(enrolled, key) in dashboard.year_enrolled">
                                            <td>{{ key }} Year</td>
                                            <td>{{ enrolled }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="card-footer">
                            <router-link :to="{name: 'enrolls'}" class="small-box-footer">
                                More info <i class="fas fa-arrow-circle-right"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </section>
    <div v-else>
        <not-found></not-found>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                title: 'Dashboard',
                routePrefixName: 'dashboard',
                ifReady: false,
                dashboard: ''
            };
        },
        methods:  {
            loadAdminData(){
                axios.get("/api/dashboard/admin").then((data) => {
                    this.dashboard = data.data.data;
                    this.ifReady = true;
                });
            },
        }, 
        mounted() {
            if (this.$gate.isTeacher()){
                this.$router.push({ name: 'grade-sheets' });
            } else if (this.$gate.isStudent()){
                this.$router.push({ name: 'grades.student' });
            } else if (this.$gate.isRegistrar() || this.$gate.isDepartment()){
                this.$router.push({ name: 'registrations' });
            }
             else {
                this.loadAdminData();
            }
            console.log('Component mounted.')
        }
    }
</script>
