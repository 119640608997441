import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: {},
        setting: {}
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        getSetting(state) {
            return state.setting;
        },
    },
    mutations: {
        SET_USER: (state, data) => {
            state.user = data;
        },
        SET_SETTING: (state, data) => {
            state.setting = data;
        },
    },
    actions: {
        setUser: ({commit, state}, data) => {
            commit('SET_USER', data);
            return true;
        },
        setSetting: ({commit, state}, data) => {
            commit('SET_SETTING', data);
            return true;
        },
    },
    modules: {
        //
    }
});
