<template>
    <section class="content" v-if="$gate.isAdmin()">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card card-light">
                        <div class="card-header">
                            <h3 class="card-title">Semesters List</h3>
                            <div class="card-tools"> 
                                <!-- <button class="btn btn-light" @click="resetSemesters">
                                    <i class="fas fa-sync-alt"></i>
                                    Reset
                                </button> -->
                                <button class="btn btn-light" @click="showSearchModal">
                                    <i class="fas fa-fw fa-search"></i>
                                    Search
                                </button>                          
                                <button type="button" class="btn btn-light" @click="newModal">
                                    <i class="fa fa-plus-square"></i>
                                    Add New
                                </button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive p-0">
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>School Year</th>
                                        <th>Created By</th>
                                        <th>Created Date</th>
                                        <th class="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="semester in semesters.data" :key="semester.id">
                                        <td>{{ semester.name }}</td>
                                        <td>{{ semester.school_year.year_from }}  - {{ semester.school_year.year_to }}</td>
                                        <td>{{ semester.user.first_name }} {{ semester.user.middle }} {{ semester.user.last_name }}</td>
                                        <td>{{semester.created_at | myDate }}</td>
                                        <td class="text-center">
                                            <a href="#" @click="editModal(semester)">
                                                <i class="fa fa-edit blue"></i>
                                            </a>
                                            &nbsp
                                            <a href="#" @click="deleteSemester(semester.id)">
                                                <i class="fa fa-trash red"></i>
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <pagination :data="semesters" @pagination-change-page="getResults" :limit="1"></pagination>
                <!-- /.card -->
                </div>
            </div>
            <!-- Modal -->
            <div class="modal fade" id="addNewSemester" tabindex="-1" role="dialog" aria-labelledby="addNewSemester" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" v-show="!editmode">Create New Semester</h5>
                            <h5 class="modal-title" v-show="editmode">Update Semester's Info</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="editmode ? updateSemester() : createSemester()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label><a class="red">* </a> Name</label>
                                    <input v-model="form.name" type="text" name="name"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                                    <has-error :form="form" field="name"></has-error>
                                </div>
                                <div class="form-group">
                                    <label>Description</label>
                                    <input v-model="form.description" type="text" name="description"
                                        class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                                    <!-- <has-error :form="form" field="description"></has-error> -->
                                </div>
                                <div class="form-group">
                                    <label><a class="red">* </a> School Year</label>
                                    <v-select label="name" v-model="form.school_year" :options="school_years"  @input="selectedSchoolYear"></v-select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="searchSemesterModal" tabindex="-1" role="dialog" aria-labelledby="searchSemesterModal" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Search Semester</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form @submit.prevent="loadSemesters()">
                            <div class="modal-body">
                                <div class="form-group">
                                    <label>Semester</label>
                                    <input type="text" v-model="search.name" class="form-control" placeholder="Semester">
                                </div>
                                <div class="form-group">
                                    <label>School Year</label>
                                    <v-select label="name" v-model="search_school_year" :options="school_years"  @input="selectedSchoolYearToSearch"></v-select>
                                </div>
                                <div class="form-group">
                                    <label>Order By</label>
                                    <select class="form-control" v-model="search.order_by">
                                        <option value="desc">Newest</option>
                                        <option value="asc">Oldest</option>
                                    </select>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-success">Search</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div v-else>
        <not-found></not-found>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                editmode: false,
                semesters : {},
                school_years: [],
                search: {
                    name: '',
                    school_year_id: '',
                    order_by: 'desc'
                },
                search_school_year: {},
                school_year: {},
                form: new Form({
                    id : '',
                    name: '',
                    description: '',
                    school_year_id: '',
                    school_year: {},
                    user: {},
                    display: ''
                })
            }
        },
        methods: {
            getResults(page = 1) {
                let params = this.search;
                this.$Progress.start();
                axios.get('/api/semesters?page=' + page, { params }).then(({ data }) => (this.semesters = data));
                this.$Progress.finish();
            },
            loadSemesters(){
                // if(this.$gate.isAdmin()){
                let params = this.search;
                axios.get("/api/semesters", {params}).then(({ data }) => (this.semesters = data));
                $('#searchSemesterModal').modal('hide');
                // }
            },
            loadSchoolYears(){
                axios.get("/api/school-years/get-all-school-years").then(({ data }) => (this.school_years = data.data));
                $('#searchSemesterModal').modal('hide');
            },
            createSemester(){
                this.$Progress.start();
                this.form.post('/api/semesters')
                .then((data)=>{
                    $('#addNewSemester').modal('hide');
                    Toast.fire({
                        icon: 'success',
                        title: data.data.message
                    });
                    this.$Progress.finish();
                    this.loadSemesters();
                })
                .catch(()=>{
                    Toast.fire({
                        icon: 'error',
                        title: 'Some error occured! Please try again'
                    });
                })
            },
            updateSemester(){
                this.$Progress.start();
                this.form.put('/api/semesters/'+this.form.id)
                .then((response) => {
                    // success
                    $('#addNewSemester').modal('hide');
                    Toast.fire({
                      icon: 'success',
                      title: response.data.message
                    });
                    this.$Progress.finish();
                    this.form.reset();
                        //  Fire.$emit('AfterCreate');
                    this.loadSemesters();
                })
                .catch(() => {
                    this.$Progress.fail();
                });
            },
            editModal(semester){
                this.editmode = true;
                this.form.reset();
                $('#addNewSemester').modal('show');
                this.form.fill(semester);
            },
            showSearchModal(){
                this.form.reset();
                $('#searchSemesterModal').modal('show');
            },
            newModal(){
                this.editmode = false;
                this.form.reset();
                $('#addNewSemester').modal('show');
            },
            deleteSemester(id){
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.form.delete('/api/semesters/'+id).then(()=>{
                            Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                            );
                            this.loadSemesters();
                        }).catch((data)=> {
                            Swal.fire("Failed!", data.message, "warning");
                        });
                    }
                })
            },
            resetSemesters() {
                this.search = {
                    name: '',
                    school_year_id: '',
                    order_by: 'desc'
                };

                this.loadSemesters();
            },
            selectedSchoolYear(value) {
                this.form.school_year_id = value.id
                this.form.display = `${this.form.name} - (${value.year_from} - ${value.year_to})`; 
            },
            selectedSchoolYearToSearch(value) {
                this.search.school_year_id = value.id
            }
        },
        mounted() {
            
        },
        created() {
            this.$Progress.start();
            this.loadSemesters();
            this.loadSchoolYears();
            this.$Progress.finish();
        }
    }
</script>
